import React       from 'react';
import { graphql } from 'gatsby';
import { Box }     from '@chakra-ui/react';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Lightbox     from '@interness/web-core/src/components/media/Lightbox/Lightbox';
import Center       from '@interness/web-core/src/components/structure/Center/Center';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';


const BedachungenPage = (props) => {
  return (
    <>
      <HeaderMedia id="header-bedachung"/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle="Im Wandel der Zeit">Bedachungen</Heading>
        <Box textAlign="justify">
          <p>
            Wohnräume im Dach bieten Ihnen neben zusätzlich nutzbarer Fläche ein ganz besonderes, behagliches
            Wohngefühl.
            Je nach den Gegebenheiten des Daches und der Nutzung des entstehenden Wohnraums hat man verschiedene
            Möglichkeiten der Gauben- und Loggiengestaltung. So kann durch den Einbau von Gauben bei flach geneigten
            Dächern viel zusätzlicher Platz und Stehhöhe gewonnen werden.
            Für alle Gestaltungen stehen Ihnen heute vielfältigste Möglichkeiten zur Verfügung. Diese dienen nicht nur
            der
            Ausgestaltung des Wohnraums, sondern prägen auch den Charakter des Hauses wesentlich mit. Egal, ob beim
            Neubau
            oder auch bei einem späteren Ausbau, hier ist vor allem die fachliche Kompetenz gefragt.
          </p>
        </Box>
        <Spacer/>
        <Heading tag="h2">Übersicht Bedachungen</Heading>
        <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} fromApi/>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default BedachungenPage;

export const query = graphql`
    query {
        gallery: directusMediaCollection(name: {eq: "bedachungen-gallery"}) {
            ...LightboxGalleryGrid
        }
    }
`;